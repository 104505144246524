// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homeHero {\n  height: calc(100vh - 62px);\n}\n@media (min-width: 768px) {\n  .homeHero {\n    height: calc(100vh - 90px);\n  }\n}\n.homeHero .content,\n.homeHero .content .wrapper {\n  height: 100%;\n}\n.homeHero .homeHeroWrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 7vh 0 20vh;\n}\n@media (max-width: 768px) and (max-height: 550px) {\n  .homeHero .homeHeroWrapper {\n    padding: 5vh 0 20vh;\n  }\n}\n@media (min-height: 768px) {\n  .homeHero .homeHeroWrapper {\n    padding: 10vh 0 15vh;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/home/Hero.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;AAAF;AAEE;EAHF;IAII,0BAAA;EACF;AACF;AACE;;EAEE,YAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEI;EAPF;IAQI,mBAAA;EACJ;AACF;AACI;EAXF;IAYI,oBAAA;EAEJ;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.homeHero {\n  height: calc(100vh - 62px);\n\n  @media (min-width: $media-md) {\n    height: calc(100vh - 90px);\n  }\n\n  .content,\n  .content .wrapper {\n    height: 100%;\n  }\n\n  .homeHeroWrapper {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 7vh 0 20vh;\n\n    @media (max-width: $media-md) and (max-height: 550px) {\n      padding: 5vh 0 20vh;\n    }\n\n    @media (min-height: $media-md) {\n      padding: 10vh 0 15vh;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
