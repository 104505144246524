// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo {\n  display: block;\n  font-family: \"Montserrat Alternates\", \"Arial\", sans-serif;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 16px;\n  line-height: 20px;\n  letter-spacing: 0.2em;\n}\n\n.light.logo span:first-child {\n  color: rgba(243, 242, 232, 0.7);\n}\n.light.logo span:last-child {\n  color: #F3F2E8;\n}\n\n.dark.logo span:first-child {\n  color: rgba(34, 35, 38, 0.6);\n}\n.dark.logo span:last-child {\n  color: #222326;\n}", "",{"version":3,"sources":["webpack://./src/components/logo/Logo.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,yDCKe;EDJf,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAAF;;AAIE;EACE,+BAAA;AADJ;AAIE;EACE,cAAA;AAFJ;;AAOE;EACE,4BAAA;AAJJ;AAOE;EACE,cAAA;AALJ","sourcesContent":["@import \"../../styles/variables\";\n\n.logo {\n  display: block;\n  font-family: $secondary-font;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 16px;\n  line-height: 20px;\n  letter-spacing: 0.2em;\n}\n\n.light.logo {\n  span:first-child {\n    color: rgba(243, 242, 232, 0.7);\n  }\n\n  span:last-child {\n    color: #F3F2E8;\n  }\n}\n\n.dark.logo {\n  span:first-child {\n    color: rgba(34, 35, 38, 0.6);\n  }\n\n  span:last-child {\n    color: #222326;\n  }\n}","@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');\n\n//COLORS\n$dark-color: #262222;\n$light-color: #F3F2E8;\n$lighter-color: #F3F1E8;\n\n//FONTS\n$main-font: 'Montserrat', 'Arial', sans-serif;\n$secondary-font: 'Montserrat Alternates', 'Arial', sans-serif;\n\n//BREAKPOINTS\n$media-xs: '480px';\n$media-sm: '576px';\n$media-md: '768px';\n$media-lg: '992px';\n$media-xl: '1200px';\n$media-xxl: '1600px';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
