// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content {\n  padding: 0 20px;\n}\n@media (min-width: 768px) {\n  .content {\n    padding: 0 94px;\n  }\n}\n@media (min-width: 992px) {\n  .content {\n    padding: 0 100px;\n  }\n}\n.content .wrapper {\n  margin: 0 auto;\n  width: 100%;\n  max-width: 1166px;\n}", "",{"version":3,"sources":["webpack://./src/components/layout/Content.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AAAF;AAEE;EAHF;IAII,eAAA;EACF;AACF;AACE;EAPF;IAQI,gBAAA;EAEF;AACF;AAAE;EACE,cAAA;EACA,WAAA;EACA,iBAAA;AAEJ","sourcesContent":["@import \"../../styles/variables\";\n\n.content {\n  padding: 0 20px;\n\n  @media (min-width: $media-md) {\n    padding: 0 94px;\n  }\n\n  @media (min-width: $media-lg) {\n    padding: 0 100px;\n  }\n\n  .wrapper {\n    margin: 0 auto;\n    width: 100%;\n    max-width: 1166px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
