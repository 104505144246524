// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aboutCallToAction {\n  background-color: #262222;\n  color: #F3F1E8;\n}\n.aboutCallToAction .aboutCallToActionWrapper {\n  display: grid;\n  gap: 40px;\n  padding: 70px 0 51px;\n}\n@media (min-width: 768px) {\n  .aboutCallToAction .aboutCallToActionWrapper {\n    gap: 80px;\n    padding: 120px 0 100px;\n  }\n}\n@media (min-width: 992px) {\n  .aboutCallToAction .aboutCallToActionWrapper {\n    gap: 116px;\n    padding: 156px 0 135px 198px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/about/CallToAction.scss","webpack://./src/styles/mixins.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGA;ECKE,yBCLW;EDMX,cCJc;AFAhB;AACE;EACE,aAAA;EACA,SAAA;EACA,oBAAA;AACJ;AACI;EALF;IAMI,SAAA;IACA,sBAAA;EAEJ;AACF;AAAI;EAVF;IAWI,UAAA;IACA,4BAAA;EAGJ;AACF","sourcesContent":["@import \"../../styles/variables\";\n@import \"../../styles/mixins\";\n\n.aboutCallToAction {\n  @include darkTheme;\n\n  .aboutCallToActionWrapper {\n    display: grid;\n    gap: 40px;\n    padding: 70px 0 51px;\n\n    @media (min-width: $media-md) {\n      gap: 80px;\n      padding: 120px 0 100px;\n    }\n\n    @media (min-width: $media-lg) {\n      gap: 116px;\n      padding: 156px 0 135px 198px;\n    }\n  }\n}","@import \"./variables\";\n\n@mixin lightTheme {\n  background-color: $light-color;\n  color: $dark-color;\n}\n\n@mixin darkTheme {\n  background-color: $dark-color;\n  color: $lighter-color;\n}","@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');\n\n//COLORS\n$dark-color: #262222;\n$light-color: #F3F2E8;\n$lighter-color: #F3F1E8;\n\n//FONTS\n$main-font: 'Montserrat', 'Arial', sans-serif;\n$secondary-font: 'Montserrat Alternates', 'Arial', sans-serif;\n\n//BREAKPOINTS\n$media-xs: '480px';\n$media-sm: '576px';\n$media-md: '768px';\n$media-lg: '992px';\n$media-xl: '1200px';\n$media-xxl: '1600px';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
