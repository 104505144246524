import {FC} from "react";
import { DefaultLayout } from "../../components/layout/DefaultLayout";
import {Content} from "../../components/layout/Content";
import {Title} from "../../components/typography/Title";
import './index.scss'

const jobs = {
  design: [
    {
      title: 'Product Designer',
      description: 'We’re looking for a detail oriented, skilled, team-playing & empathic product designer to help our team in thinking, designing & imagining.',
    },
    {
      title: '3D Artist',
      description: 'Seeking for a human who is a 3d designer with a keen eye, a sense of humor and a taste for a tough challenge to join us.',
    },
    {
      title: 'SFX Designer',
      description: 'We have a new and exciting opportunity to join as an experienced Sound Designer to help us deliver first class audio experiences.',
    },
  ],
  development: [
    {
      title: 'React Developer',
      description: 'For our international team with clients from across the UK and US, at our front-end stack to solve complex puzzles.',
    },
  ],
  'marketing & communications': [
    {
      title: 'Lead Generation Ninja',
      description: 'If you are creative and result-driven lead generation specialist to join our expanding marketing & sales department.',
    },
  ],
}

const Join: FC = () => {
  return (
    <DefaultLayout theme="dark" title="Join">
      <Content className="joinPage">
        <Title level={2} className="joinPageTitle">Join</Title>
        <div className="joinListWrapper">
          <div className="joinListInner">
            {Object.entries(jobs).map(([domain, jobs]) => (
              <div className="domainItem">
                <p className="jobDomainTitle">{domain}</p>
                <div className="domainJobs">
                  {jobs.map(job => (
                    <div className="domainJobItem">
                      <Title level={5}>{job.title}</Title>
                      <p className="jobDescription">{job.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Content>
    </DefaultLayout>
  )
}

export default Join
