// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homePageCases {\n  background-color: #262222;\n  color: #F3F1E8;\n}", "",{"version":3,"sources":["webpack://./src/pages/home/Cases.scss","webpack://./src/styles/mixins.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGA;ECKE,yBCLW;EDMX,cCJc;AFAhB","sourcesContent":["@import \"../../styles/variables\";\n@import \"../../styles/mixins\";\n\n.homePageCases {\n  @include darkTheme;\n\n\n}","@import \"./variables\";\n\n@mixin lightTheme {\n  background-color: $light-color;\n  color: $dark-color;\n}\n\n@mixin darkTheme {\n  background-color: $dark-color;\n  color: $lighter-color;\n}","@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');\n\n//COLORS\n$dark-color: #262222;\n$light-color: #F3F2E8;\n$lighter-color: #F3F1E8;\n\n//FONTS\n$main-font: 'Montserrat', 'Arial', sans-serif;\n$secondary-font: 'Montserrat Alternates', 'Arial', sans-serif;\n\n//BREAKPOINTS\n$media-xs: '480px';\n$media-sm: '576px';\n$media-md: '768px';\n$media-lg: '992px';\n$media-xl: '1200px';\n$media-xxl: '1600px';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
