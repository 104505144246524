import {useParams} from "react-router-dom";
import {useEffect} from "react";

const Meet = () => {
  const params = useParams();
  // @ts-ignore
  const userId = params?.id;

  useEffect(() => {
    if (userId) {
      window.open(`https://calendly.com/${userId}-huemachine/intro-meeting`, "_self");
    } else {
      window.open('https://calendly.com/',"_self");
    }

  }, [params]);

  return <></>;
};

export default Meet;
