import {FC} from "react";
import {Content} from "../../components/layout/Content";
import './Cases.scss'

const Cases: FC = () => (
  <Content className="homePageCases">

  </Content>
)

export { Cases }
